<script>
  import { get, call } from 'vuex-pathify'
  import AccountProfileOrderHistory from '@/components/account/AccountProfileOrderHistory'
  import ProductSlider from '@/components/marketplace/ProductSlider'
  import screen from '@/helpers/screen'

  export default {
    components: {
      AccountProfileOrderHistory,
      ProductSlider,
    },
    computed: {
      ...get(['route']),
      ...get('account', ['recentlyViewedProducts']),
      ...get('marketplace', ['topTenProducts']),
      ...get('config', ['marketplaceEnabled']),
      screen,
      sliderIsRecentlyViewed() {
        return this.recentlyViewedProducts.length > 4
      },
      sliderProducts() {
        return this.sliderIsRecentlyViewed
          ? this.recentlyViewedProducts.slice(0, 10)
          : this.topTenProducts
      },
      sliderHeading() {
        return this.sliderIsRecentlyViewed ? 'Recently Viewed' : 'Top Ten'
      },
    },
    async created() {
      if (!this.sliderIsRecentlyViewed) {
        this.$store.dispatch('products/ensureFresh')
      }
    },
    methods: {
      ...call('marketplace', ['addToWaitlist', 'setFavorited']),
      ...call('marketplaceCart', ['addToCart']),
    },
  }
</script>

<template>
  <div role="tabpanel" aria-label="Orders" class="flex flex-col">
    <AccountProfileOrderHistory />
    <div v-if="marketplaceEnabled && sliderProducts.length" class="overflow-hidden px-2 my-10">
      <ProductSlider
        :products="sliderProducts"
        @addToCart="addToCart"
        @addToWaitlist="addToWaitlist"
        @setFavorited="setFavorited"
      >
        <template v-slot:heading>{{ sliderHeading }}</template>
      </ProductSlider>
    </div>
    <Portal to="modal">
      <BaseModal
        v-if="screen.md"
        size="lg"
        :open="route.name !== 'AccountProfileOrders'"
        dismissible
        @dismiss="$navigate({ name: 'AccountProfileOrders' })"
      >
        <RouterView />
      </BaseModal>
    </Portal>
  </div>
</template>
